<template>
  <tr>
    <td>{{ summary.account_type }}</td>
    <td>{{ brokerAccount.broker_name }}</td>
    <td>{{ brokerAccount.broker_account }}</td>
    <td>{{ summary.predicted_date }}</td>
    <td>{{ summary.subscription_date }}</td>
    <td class='border-l'>
      <vue-numeric
        v-model='summary.estimated_price'
        output-type='number'
        class='form-input text-right w-24'
        separator=',' />
    </td>
    <td>
      <vue-numeric
        v-model='summary.estimated_quantity'
        output-type='number'
        class='form-input text-right w-32'
        separator=',' />
    </td>
    <td>
      <select v-model='summary.is_lock_up' class='form-select'>
        <option v-for='option in options'
                :key='option.value'
                :value='option.value'>
          {{option.text}}
        </option>
      </select>
    </td>
    <td>
      <input class='form-input'
        type='text'
        style='width: 10rem'
        v-model='summary.lock_up_period'>
    </td>
    <td>
      <el-date-picker
        v-model='summary.lockup_expiration_date'
        value-format='yyyy-MM-dd'
        type='date'
        placeholder='해제일'
        style='width: 10rem'>
      </el-date-picker>
    </td>
    <td class=' border-l'>
      <vue-numeric
        v-model='summary.allocated_price'
        output-type='number'
        class='form-input text-right w-24'
        separator=',' />
    </td>
    <td>
      <vue-numeric
        v-model='summary.allocated_quantity'
        output-type='number'
        class='form-input text-right w-32'
        separator=',' />
    </td>
    <td>
      <vue-numeric
        v-model='summary.subs_fee_rate'
        output-type='number'
        class='form-input text-right w-16'
        separator=',' /> %
    </td>
    <td>
      <button @click='viewReport' class='p-1 font-semibold underline pointer'>
        기안문
      </button>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'IpoSummaryRow',
  props: [
    'summary',
    'isViewRatio',
    'isNewNOldStocks',
  ],
  data () {
    return {
      options: [
        { text: '여', value: true  },
        { text: '부', value: false },
      ],
    }
  },
  computed: {
    ...mapState('ipos', [
      'selectedIpo',
      'selectedBrokerId',
    ]),
    brokerAccount () {
      let brokerAccount = this.selectedIpo.broker_accounts.find(
                            account => account.account_type === this.summary.account_type &&
                            account.broker_id === this.selectedBrokerId
                          )
      return brokerAccount ? brokerAccount : ''
    },
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    viewReport () {
      let routeData = this.$router.resolve({
                        name: 'IpoDraft',
                        query: {
                          ipo_id: this.summary.ipo_id,
                          account_type: this.summary.account_type,
                          is_view_ratio: this.isViewRatio,
                          is_new_n_old_stocks: this.isNewNOldStocks,
                        }
                      })
      window.open(routeData.href, '_blank')
    },
  }
}
</script>
